div.burger-menu-wrapper {
  height: 100px;
  background-color: white;

  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 36px;
    top: 36px;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 36px !important;
    height: 36px !important;
    right: 52px !important;
    top: 12px !important;
  }

  /* Color/shape of close button cross */
  /* .bm-cross {
    height: 42px;
    width: 42px;
  } */

  /* Sidebar wrapper styles Note: Beware of modifying this element can break the animations */
  .bm-menu-wrap {
    width: 260px;
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    width: 260px !important;
    background: $primary;
    padding: 2.5em 1em 0;
    font-size: 1.15em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.4);
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;

    & a {
      text-decoration: none;
      color: $white;
      margin-bottom: 10px;

      display: flex;
      align-items: center;

      & img {
        margin-right: 20px;
      }

      & p {
        margin-bottom: 0px;
      }
    }
  }
}
