.makePaymentTitle{
    font-size: 16px;
    letter-spacing: 0.04rem;
    color: $primary;
    font-family: "Lato-Semibold"!important;
}
.makePaymentSelect {
    font-size: 12px;
    color: $primary;
    font-family: "Lato-Semibold"!important;
    text-transform:none!important;
}
.makePaymentSelectMt {
    margin-top: 10px;
}
.makePaymentRadioSection {
    height: 48px;
}
.makePaymentSelectDollar {
    font-family: "Lato";
    font-size: 10px;
    font-style: italic;
    line-height: 10px;
}
.makePaymentBtn {
    width: 137px!important;
    margin: 0 auto;
    margin-bottom: 40px;
}
.paymentRegister{
    padding-left:50px;
    padding-right:50px;

}

.stripeRegister{
    padding-left:10px;
    padding-right:10px;

}