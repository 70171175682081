//COLORS
// $primary: #4d6177;
$primary: #4f5d78;
$link: #ff5f58;
$warning: #ff5f58;
$danger: #ff5f58;
$white: #ffffff;

$progress-border-radius: 0;
//; $info:
//; $success:
//; $dark:
//; $text:

//TEXT;
$family-sans-serif: Lato;
$family-primary: Roboto;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 400;
$weight-semibold: 700;
$weight-bold: 700;

$help-size: 0.9rem;
$title-color: inherit;
$title-family: $family-primary;
$subtitle-family: $family-sans-serif;
$subtitle-color: inherit;
$input-hover-color: inherit;
$label-color: inherit;
$title-weight: $weight-normal;
$subtitle-weight: $weight-light;

$navbar-tab-hover-border-bottom-color: $primary;


//OPTIONS
$link-hover: darken($link, 20%);
$widescreen-enabled: false;
$fullhd-enabled: false;
$body-background-color: $white;
$input-border-color: transparent;
$input-shadow: none;
$content-heading-color: inherit;
$navbar-breakpoint: 0px;
$navbar-height: 2rem;
$navbar-dropdown-offset: 2px;

$tabs-link-active-border-bottom-color: $danger;
$tabs-link-active-color: $danger;

$menu-label-color: $white;
$menu-item-color: $white;
$menu-item-active-background-color: $danger;


@import "~bulma/bulma.sass";

// ####### OR IMPORT INDIVIDUALLY #############

// @import "~bulma/sass/utilities/_all.sass";
// @import "~bulma/sass/base/_all.sass";
// @import "~bulma/sass/form/_all.sass";
// @import "~bulma/sass/elements/_all.sass";

// COMPONENETS
// @import "~bulma/sass/components/pagination.sass";
// @import "~bulma/sass/components/tabs.sass";
// @import "~bulma/sass/components/media.sass";
// @import "~bulma/sass/components/"

// EXTENSIONS
@import "~bulma-extensions/bulma-timeline/src/sass/index.sass";
@import "~bulma-extensions/bulma-tooltip/src/sass/index.sass";
