div.loginwith {
  margin-top: 25px;
  width: 300px;

  .button {
    border: none;
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;
  }

  .or {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
       -moz-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;

    hr {
      -webkit-box-flex: 1;
         -moz-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      background-color: $light;
      height: 1px;
    }

    p {
      color: $light;
      margin: 10px 10px;
    }
  }
}

.facebook {
  width: 100%;
  background: #4267b2;
  color: white;

  &:hover {
    color: white !important;
    background: darken(#4267b2, 3%);
  }
}

.google {
  background: white;
  color: #444;

  &:hover {
    color: #444 !important;
    background: darken(white, 3%);
  }
}





/* Shared */
.loginBtn {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 10px 0px;
    padding: 0 15px 0 46px;
    border: none;
    text-align: center;
    line-height: 36px;
    height: 36px;
    white-space: nowrap;
    border-radius: 20px;
    font-size: 16px;
    color: #FFF;
    width: 300px!important;
  }
  .loginBtn:before {
    content: "";
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  -webkit-box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
          box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Google */
button.loginBtn--facebook {

}
/* Facebook */
.loginBtn--facebook {
  background-color: #ff5f58;
}
.loginBtn--facebook:before {
  border-right: #ff5f58 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 8px 6px no-repeat;

}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background: #E74B37;
}



/* Google */
button.loginBtn--google {

}
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #ff5f58;
}
.loginBtn--google:before {
  border-right: #ff5f58 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 8px 6px no-repeat;

}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}
