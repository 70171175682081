@import url("https://fonts.googleapis.com/css?family=Open+Sans:400, 600");
.adminBackground {
  background: #105469 !important;
  font-family: "Open Sans", sans-serif;
}
.adminTable {
  background: #012b39 !important;
  border-radius: 0.25em;
  border-collapse: collapse;
  margin: 1em;
}
.adminTr {
  transition: #105469 0.25s ease !important;
}
.adminTr:hover {
  background: #014055 !important;
}
.adminTd {
  color: #fff !important;
  font-weight: 400;
  padding: 0.65em 1em;
  font-size: 1.4em !important;
}
.adminTh {
  background: #012b39 !important;
  border-bottom: 1px solid #364043 !important;
  color: #e2b842 !important;
  font-size: 1.2em !important;
  font-weight: 600;
  padding: 0.8em 1.4em;
  text-align: left;
  text-transform: uppercase;
}
.adminTable.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #105469;
}
.adminTable.table.is-striped tbody tr:not(.is-selected):nth-child(odd) {
  background-color: #105469;
}
.adminTable.table {
  border: 0px solid transparent;
}
.liftReviewBtn {
  width: 125px !important;
  padding: 20px !important;
}
.adminTable td {
  font-size: 17px;
  border: none;
}

.adminFont {
  font-size: 30px;
}

.liftReviewRow {
  display: flex;
  justify-content: space-between;
}
.liftReviewCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.minWidth370 {
  margin-top: 20px;
  width: 370px;
  min-width: 370px;
}
.liftReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.blackBackground {
  background-color: black;
  font-weight: bold;
  color: white;
}
.liftReviewContainer2Col {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  min-width: 820px;
  flex-wrap: wrap;
  overflow-x: auto;
}
.liftReviewContainer2Col > .box:not(:last-child) {
  margin-bottom: 0px;
}
.adminSearchBtn {
  width: 150px!important;
  height: 48px!important;
  padding: 10px!important;
}
.tableTitle {
  margin-left: 20px;
}
.list-container {
  margin-left: 20px;
}
.adminMargin {
  margin-left: 20px;
  margin-right: 20px;
}