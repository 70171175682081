@charset "utf-8";
@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato'), url('../../fonts/Lato/Lato-Bold.ttf')  format('truetype'), 
}
@font-face {
    font-family: 'Lato-Light';
    src: local('Lato'), url('../../fonts/Lato/Lato-Light.ttf')  format('truetype'), 
}
@font-face {
    font-family: 'Lato-Semibold';
    src: local('Lato'), url('../../fonts/Lato/Lato-Semibold.ttf')  format('truetype'), 
}
@font-face {
    font-family: 'Lato-Black';
    src: local('Lato'), url('../../fonts/Lato/Lato-Black.ttf')  format('truetype'), 
}

@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato'), url('../../fonts/Lato/Lato-Regular.ttf')  format('truetype'), 
}
@import url("https://fonts.googleapis.com/css?family=Lato:300,700|Roboto:400,700&display=swap");

@import "./bulma.scss";
@import "./burger.scss";
@import "./logins.scss";
@import "./video.scss";
@import "./overrides.scss";
@import "./logins.scss";
