.orangeBox {
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  background: $warning;
  color: white;
}
.orangeBoxReverse {
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  width: 20px;
  height: 20px;

  background: #f3f2f3;
  color: #656665;
}
.orangeBoxOuter {
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 2px;
  width: 28px;
  height: 28px;
  background: #ebebeb;
  display: flex;
  justify-content: space-between;

  align-items: center;
}
.orangeBoxContainer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 270px;
  justify-content: space-between;
}

.latoBold {
  font-family: "lato";
  font-weight: bold;
  text-align: left;
}
.liftRequestTitle {
  @extend .latoBold;
  font-size: 16px;
  letter-spacing: 0.04em;
  margin-top: 10px;
}
.liftRequestGap {
  @extend .latoBold;
  font-size: 16px;
  letter-spacing: 0.04em;
  margin-top: 30px;
}

.boosterCode {
  @extend .latoBold;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}

.orangeBorderInput {
  padding-left: 10px;
  border: $warning solid 1px !important;
  border-radius: 14px !important;
  // width: 270px!important;
  height: 31px !important;
  margin-top: 8px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
}
.noBorderInput {
  padding-left: 27px;
  padding-bottom: 5px;

  border-radius: 14px !important;
  width: 76px !important;
  height: 31px !important;
  margin-top: 8px !important;
  border: 1px solid $primary;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  box-shadow: none;
}
.primaryBorderInput.ssnInput {
  width: 140px !important;
}
.primaryBorderInput {
  padding-left: 10px;
  border: $primary solid 1px !important;
  border-radius: 14px !important;
  width: 270px !important;
  height: 36px !important;
  margin-top: 8px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
}

#orangeBorderInputMoney {
  width: 111px !important;
  height: 31px !important;
  border-radius: 11px !important;
}

#orangeBorderInputFirstName {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputLastName {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputCity {
  width: 131px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputZip {
  width: 65px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}

#orangeBorderInputPhoneNumber {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}

#orangeBorderInputVerifyCode {
  width: 100px !important;
  height: 35px !important;
  border-radius: 25px !important;
}

.orangeBorderInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}

.orangeBorderInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}

.orangeBorderInput::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}
.liftRequestSideNote {
  font-size: 10px;
  font-family: "Lato";
  text-align: left;
}
.formFieldOrange {
  margin-top: 15px;
}
.orangeFormField {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 271px !important;
  height: 42px !important;
}
.orangeFormField > select {
  border: $warning solid 1px !important;
  border-radius: 17px !important;
  width: 271px !important;
  height: 42px !important;
  font-family: "Lato" !important;
  font-size: 18px !important;
  padding-left: 12px;
}
.orangeFormFieldSmall {
  margin-top: 6px;
  margin-bottom: 8px;
  width: 63px !important;
  height: 31px !important;
  font-family: "Lato" !important;
  font-size: 13px !important;
}
.orangeFormFieldSmall > select {
  border: $warning solid 1px !important;
  border-radius: 5px !important;
  width: 63px !important;
  height: 31px !important;
  padding-left: 12px;
  color: $primary !important;
  font-family: "Lato" !important;

  font-weight: normal;
}

.orangeFormFieldMedium {
  margin-top: 6px;
  margin-bottom: 8px;
  // width: 210px!important;
  height: 40px !important;
  font-family: "Lato" !important;
  font-size: 14px !important;
}
.orangeFormFieldMedium > select {
  border: $warning solid 1px !important;
  border-radius: 15px !important;
  // width: 210px!important;
  height: 41px !important;
  padding-left: 12px;
  color: $primary !important;
  font-family: "Lato" !important;

  font-weight: normal;
}
.orangeFormFieldSmall:after {
  border-color: $warning !important;
}
.labelOrange {
  font-family: "Lato-Semibold";
  font-size: 14px;
  letter-spacing: 0.03em;
}
.label2 {
  display: flex;
  margin-top: 5px;
  font-family: "Lato" !important;
  font-size: 11px !important;
  letter-spacing: 0.01rem;
}
.nav-text-space {
  letter-spacing: 0.01rem !important;
}
.radioButtonVals {
  font-family: "Lato-Semibold";
  font-size: 13px;
}
.liftRocketBackground {
  background: radial-gradient(lighten($warning, 25%), white);
  background-size: 100% 100%;
}

.liftRequestContainer {
  width: 300px;
  margin: auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
}

.liftRequestContainerLeft {
  // width: 300px;
  // padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  // display: flex;
  // flex-direction: column;
  margin-left: 100px;
}

.liftRequestContainer2 {
  width: 270px;
  margin: auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.liftRequestContainerWhite {
  width: 320px;
  margin: auto;
  padding-left: 5px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
}

.liftRequestContainerSmaller {
  width: 250px;
  margin: auto;
  padding-left: 5px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
}

.btnHalfRightContainer {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  @media screen and (max-width: 769px) {
    justify-content: left;
  }
}
.btnHalfRightContainer.bankBtnContainer {
  padding-bottom: 10px !important;
}
.btnHalfRight {
  border-radius: 25px 0px 0px 25px;
  width: 160px !important;
  height: 45px !important;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: bold;
}

.btnHalfRightSemiBold {
  border-radius: 25px 0px 0px 25px;
  width: 160px !important;
  height: 45px !important;
  font-family: "Lato-Semibold";
  font-size: 16px;
  letter-spacing: 0.02em;
}

button.button.is-rounded {
  line-height: 0;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 4em;
  padding-right: 4em;
}

.liftRequestSubTitle {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato";
  font-weight: bold;
}
.liftRequestParagraph {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 10px;
  font-family: "Lato";
}
.liftRequestParagraph2 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato-Semibold";
  width: 100%;
  // padding-left:25px;
}

.liftRequestParagraphReadytoHelp {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato-Semibold";
  width: 100%;
  padding-left: 25px;
}

.liftRequestParagraph3 {
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato";
}
.liftRequestParagraph4 {
  text-align: left;
  font-size: 11px;
  font-family: "Lato-Semibold";
  line-height: 24px;
}
.liftRequestBankBody {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Lato-Semibold";
  margin-bottom: 10px;
  letter-spacing: 0.02em;
}

.liftRequestBankBody2 {
  margin-top: 10px;
  text-align: left;
  font-size: 11px;
  font-family: "Lato-Light";
  margin-bottom: 10px;
  letter-spacing: 0.02em;
}

.liftRequestNoImpact {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  font-family: "Lato-Light";
  margin-bottom: 0px;
  letter-spacing: 0.02em;
  padding-bottom: 2px;
}
.extraSpace {
  padding-bottom: 40px;
}

.liftRequestParagraphContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.btnUploadPhoto {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 198px !important;
  height: 38px !important;
}
.UploadPhotoSideNote {
  font-size: 9px;
  font-weight: bold;
}
.imageUploadFrame {
  margin: 0 auto;
  width: 290px;
  height: 211px;
  border: $warning 1px solid;
  border-radius: 10px;
  background-color: white;
  margin-top: 15px;
}
#textareaOragne {
  border: $warning 1px solid !important;
  width: 290px !important;
  height: 339px !important;
  min-width: 290px !important;
}
.liftRequestShareContainer {
  display: flex;
  width: 320px;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 25px;
}
.liftRequestShareBox {
  width: 105px;
  height: 105px;
  cursor: pointer;
}
.liftRequestShareBoxText {
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato";
}
.liftRequestShareBoxText {
  color: $primary;
}
.liftRequestRadioContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;
}
.liftRequestRadioTitle {
  font-family: "Lato";
  font-size: 14px;
  margin-top: 20px;
}
.formRadioOrange {
  margin-top: 5px;
  font-family: "Lato";
  font-size: 10px !important;
}
.formRadioOrange > .control {
  margin-top: 5px;
  font-family: "Lato";
  font-size: 10px !important;
}
.generateQrBtn {
  width: 170px !important;
}
.generateQrBtnContainer {
  margin-top: 30px;
  text-align: left;
}
.checkCircle {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}
.btnGenerateCode {
  margin-top: 20px;
  font-size: 12px;
  font-family: "Lato";
  font-weight: bold;
  width: 225px;
  height: 31px;
}
.bankCenter {
  display: flex;
  justify-content: center;
}
// .btnBank, .btnBank:visited {
//     font-size: 19px!important;
//     font-family: "Lato"!important;
//     font-weight: bold!important;
//     width: 170px!important;
//     height: 55px!important;
//     border-radius: 25px!important;
//     padding-top: 10px!important;
//     padding-bottom: 10px!important;
//     margin-top:25px;
// }

.btnBank,
.btnBank:visited {
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;

  color: $primary !important;
  background-color: white !important;
  border-radius: 25px 25px 25px 25px !important;

  @media screen and (max-width: 769px) {
    box-shadow: none !important;
    color: white !important;
    background-color: $warning !important;
    border-radius: 25px 0px 0px 25px !important;
  }
}

#liftRequest2Input {
  width: 267px !important;
  height: 42px !important;
}

#liftRequest2InputFamilyMember {
  // width: 270px!important;
  height: 42px !important;
  // padding-left:10px;
}
#liftRequest3InputSign {
  width: 76px !important;
  height: 17px !important;
  padding-left: 25px;
  border: 1px solid $primary;
}
#liftRequest2InputSign {
  width: 265px !important;
  height: 42px !important;
  padding-left: 25px;
}
.centerDiv {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.dollarSign {
  height: 50px !important;
  margin-top: 3.5px;
  margin-left: 2px;
  font-weight: normal;
  color: $primary !important;
}
.dollarOutside {
  margin-right: 10px;
  margin-left: 20px;
  font-size: 10px;
  font-family: "Lato";
  font-style: italic;
}
.radioYesNoInput {
  height: 31px;
}
.radioYesNo {
  height: 31px;
  line-height: 28px;
  margin-left: 5px;
  font-family: "Lato-Semibold";
}

.radioSometimesNotatAll {
  height: 31px;
  line-height: 28px;
  margin-left: 5px;
  font-family: "Lato-Semibold";
}

.liftRequestMoreSpace {
  padding-bottom: 40px;
}
.liftRequestBoosterInfo {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Lato";
  text-align: center;
  margin-bottom: 20px;
}
