.liftDashBoardFont {
    font-size: 14px!important;
    font-family: 'Lato'!important;
    // font-family: 'Lato-Semibold'!important;
}
.boosterFriendsLiftOnTheWay {
    font-size: 14px!important;
    // font-family: 'Lato'!important;
    text-transform:uppercase!important;
    font-family: 'Lato-Semibold'!important;
    padding-bottom:10px;
}
.liftDashBoardCol75 {
    width: 75%;
    margin-top: 10px;
}
.liftDashBoardCol25 {
    width: 25%;
    margin-top: 10px;
}
.liftDashBoardCol60 {
    width: 60%;
    margin-top: 10px;
}
.liftDashBoardCol40 {
    width: 40%;
    margin-top: 10px;
}
.liftDashBoardCol {
    width: 50%;
    margin-top: 10px;
}

.liftDashBoardColLeft {
    width: 46%;
    margin-top: 10px;
}
.liftDashBoardColRight {
    width: 54%;
    margin-top: 10px;
}
.liftDashBoardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.liftDashboardTitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
.liftDashBoardOrangeBorder {
    border: $warning solid 1px;
    border-radius: 11px;
    padding: 5px;
    margin-right: 20px;
    width: calc(50% - 20px);
}
.liftDashboardBtnHalfRightContainer {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
}
.liftDashBoardBtnHalfRight {
    border-radius: 25px 0px 0px 25px;
    width: 167px!important;
    height: 77px!important;
    font-family: "Lato";
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;

}
.liftDashboardButton {
    padding-bottom: 80px;
}
.liftDashboardNote {
    margin-top: 20px;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Lato-Semibold';
}

.rejectLiftText{
    margin-bottom:12px;

}
.liftDashboardCircleDollar {
    height: 72px;
    width: 72px;
    border:$warning solid 2px;
    border-radius: 72px;
    line-height: 72px;
    text-align: center;
    background: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.liftDashboardDollarSign {
    height: 32px;
}
.liftDashboardTopName {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $primary;
}
.liftDashboardTopInfo {
    font-size: 12px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.liftDashboardTop {
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.33px;
}
.liftDashBoardLiftInfoNumber {
    font-size: 22px;
    font-weight: bold;
    text-align:center;
}
.liftDashBoardLiftInfoPs {
    font-size: 10px;
}
.liftDashBoardBoosterLiftInfoPs {
    font-size: 8px;
}

.liftDashSoftError {
    font-size: 12px;
    font-weight: bold;

}

.liftDashBoardLiftInfoBox {
    background-color: white;
    border-radius: 20px;
    padding: 12px;
    color: $primary;

}
.liftDashBoardBoosterLiftInfoBox {
    background-color: $primary;
    border-radius: 20px;
    padding: 12px;
    color: white;
    width: 120px;
    text-align: center;
}
.liftDashBoardBoosterInfo {
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    margin-top: 20px;
    letter-spacing:0.33px;
}
.liftDashBoardBoosterContainer {
    margin-top: 30px;
    text-align: center;
}
.liftDashBoardBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    
}
.liftDashBoardBtnContainerCenter {
    display: flex;
    justify-content: center;
    
}
.liftDashboardQuestionContainer {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 40px!important;
    letter-spacing:0.33px;
}


.liftBoosterOnBoardingQuestionContainer{
    max-width: 300px;
    margin: 0 auto;
    letter-spacing:0.33px;
}

.liftDashboardQuestionRow {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $primary;
    display: flex;
}
.liftDashboardQuestionRow {
    font-size: 11px;
    
}
.liftDashboardQuestionRowSign {
    margin-bottom:10px;
    height: 26px;
    margin-right: 10px;
    cursor: pointer;
}
.liftDashboardQuestionRowInfo {
    height: 26px;
    padding-top:2px;
    line-height: 16px;
}

.liftDashboardQuestionRowInfo2 {
    height: 26px;

    line-height: 18px;

}
.liftDashboardQuestionRowTableInfo{
    font-size: 11px;
    font-family:"Roboto";
    color: $primary;

}



.moreFaqBtn {
    background-color: white!important;
    color: $primary!important;
    font-size: 12px!important;
    font-family: "Lato"!important;
    width: 166px!important;
    height: 40px!important;
}
.moreFaqBtnSign {
    width: 20px;
    height: 20px;
    background-color: $warning;
    color: white;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 10px;
}
.moreFaqBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.liftDashBoardProgressContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}
.liftDashBoardProgress {
    border-radius: 10px!important;
    background-color: white;
}
.liftDashBoardProgressInfo {
    font-size: 14px;
    margin-top: 20px;
}
.liftDashBoardProgressOrangeCircle {
    margin-top: 4.5px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: $warning;
}
.liftDashBoardProgressInfoRow {
    display: flex;
}
.liftDashBoardProgressInfoDate {
    margin-left: 15px;
}
.liftDashBoardBoosterName {
    color: $primary;
    text-align: center;
    margin-top: 30px;
    text-transform: uppercase;
}
.liftDashBoardPIFPContainer {
    text-align: center;
}
.liftDashBoardPIFPTitle1 {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 20px;
    
}
.liftDashBoardPIFPInfo1 {
    font-size: 14px;
    margin: 10px 0px;
    font-weight:bold;
    letter-spacing:0.33 px;
}
.liftDashBoardPIFPTitle2 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    
    
}
.liftDashBoardPIFPInfo2 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing:0.33px;

    margin: 10px 0px;
    margin-bottom: 20px;
}
.liftDashBoardBoosterInfo {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family:'Lato-Semibold'
    
    
}
.paybackBtn {
    margin-top: 30px;
    background-color: white!important;
    color: $primary!important;
    padding: 23px 35px!important;
    font-size: 12px;
    letter-spacing: 0.1rem;

}
.paybackBtn.is-loading{
    box-shadow: 5px 10px 20px rgb(155, 148, 148)!important;
}

.requestBtn {
    margin-top: 30px;
    background-color: white!important;
    color: $primary!important;
    margin-left: 60px;
    margin-right: 60px;
    // margin-top: 70px;
    // margin-bottom:90px;
    font-size: 12px;
    letter-spacing: 0.1rem;

}
.redMessage {
    color: red!important;
}

.cancelBtn {
    text-decoration: underline;
    font-family:'Lato-Semibold';
    font-size: 12px;
    letter-spacing: 0.03rem;
    color: $primary!important;
    text-align: center;

}




// #nextBtn.nextBtn.is-loading{
   
//     background-color: green!important;
    
    
// }
#darkLoading.is-loading::after{
    animation: spinAround 500ms infinite linear!important;
    border: 2px solid $primary!important;
    border-radius: 290486px!important;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    content: ""!important;
    display: block!important;
    height: 1em!important;
    position: relative!important;
    width: 1em!important;
    position: absolute!important;
    left: calc(50% - (1em / 2))!important;
    top: calc(50% - (1em / 2))!important;
        
}
#darkLoadingNextBtn.is-loading::after{
    animation: spinAround 500ms infinite linear!important;
    border: 2px solid $primary!important;
    border-radius: 290486px!important;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    content: ""!important;
    display: block!important;
    height: 1em!important;
    position: relative!important;
    width: 1em!important;
    position: absolute!important;
    left: calc(50% - (1em / 2))!important;
    top: calc(50% - (1em / 2))!important;
    @media screen and (max-width: 769px){
        animation: spinAround 500ms infinite linear!important;
        border: 2px solid white!important;
        border-radius: 290486px!important;
        border-right-color: transparent!important;
        border-top-color: transparent!important;
        content: ""!important;
        display: block!important;
        height: 1em!important;
        position: relative!important;
        width: 1em!important;
        position: absolute!important;
        left: calc(50% - (1em / 2))!important;
        top: calc(50% - (1em / 2))!important;
    }
   
    
    
}

.liftDashBoardWhitePaymentBox{
    background-color: white;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 50px!important;
    padding-bottom: 30px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 12px;
}


.liftDashBoardWhiteStripInfoBox{
    background-color: white;
    margin-top: 30px;
    margin-bottom: 0px;
    padding-top: 50px!important;
    padding-bottom: 0px;
    border-radius: 65px;
    font-size: 12px;
    padding-right:0px;
    width:300px;

}


.liftDashBoardWhiteBox {
    background-color: white;
    margin-top: 20px;
    padding: 20px 10px;
    padding-top: 0px!important;
    padding-bottom: 40px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 12px;
}
.liftDashBoardNotification{
    background-color: $primary;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    height: 24px;
    line-height: 24px;
}
.liftDashBoardNotificationPic {
    margin-right: 10px;
}
.liftDashBoardWhiteAmount {
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
}
.liftDashBoardNewLift {
    font-size: 16px;
}
.liftDashBoardNewLiftInfo {
    width: 205px;
    margin: 0 auto;
}
.padddingBotton0 {
    padding-bottom: 0px!important;
}
.moreSpaceInside {
    padding-bottom: 80px!important;
}