.settingNav {
  width: 320px;
  display: flex;
  border-bottom: 1px solid #e2e8ed;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
}
.settingNavIcon {
  width: 19px;
  cursor: pointer;
}
.settingNavTitle {
  font-size: 20px;
  font-family: "Lato";
  color: $warning;
  text-align: center;
  margin-left: 115px;
  line-height: 15px;
  text-transform: uppercase;
}
.settingRow {
  display: flex;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 10px;
  height: 60px;
}
.settingRowTitle {
  margin-left: 20px;
}
.settingRowInfo {
  padding-top: 10px;
  font-size: 12px;
  text-align: left;
  height: 60px;
}
.settingBorder {
  border-bottom: 1px solid #e2e8ed;
}
.settingRowInfoItem {
  padding-top: 5px;
}
.settingInput {
  width: 100%;
  height: 25px;
  border: None !important;
  border-bottom: 1px solid #e2e8ed !important;
}
.settingRowBank {
  display: flex;
  padding-top: 15px;
  border-bottom: 1px solid #e2e8ed !important;
}
.settingArrow {
  margin-left: 140px;
}
.settingBtn {
  background-color: $primary;
  color: white;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-family: "Lato-Semibold";
  border: 0px;
  cursor: pointer;
}

.settingBtn:hover,
.settingBtn:active,
.settingBtn:visited,
.settingBtn:focus {
  background-color: $primary;
  color: white !important;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-family: "Lato-Semibold";
  border: 0px;
  cursor: pointer;
}
.settingBtn.is-loading {
  background-color: $primary;
  color: white;
}
.settingBtnIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.is-loading > .settingBtnIcon {
  display: none;
}
.settingRowHand {
  cursor: pointer;
}
.settingStripeInfoTitle {
  font-size: 20px;
  font-family: "Lato";
  color: $warning;
  text-align: center;
  margin-right: 17px;
  line-height: 15px;
  text-transform: uppercase;
  width: 100%;
}
.settingStripeInfoData {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 27px;
  text-align: left;
  font-family: "Lato-Semibold";
  letter-spacing: 0.01em;
  text-transform: none;
}

#settingStripeInfoForm > form > * > label {
  font-family: "Lato";
  font-size: 18px;
  font-weight: normal !important;
  margin-bottom: 20px;
}
#settingStripeInfoForm > form > div > div > div > div > select {
  border-radius: 13px;
}

.my-div {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //   padding-right: 100px;
}

.dims {
  position: absolute;
  top: -180px;
  width: 100% !important;
  height: 100vh !important;
  //   float: left !important;
  //   padding-top: -800px !important;
  //   width: 100% !important;
  //   overflow: hidden !important;
  //   height: 100vh !important;
  //   padding: 10em 0em !important;
  //   border: solid 1px red !important;
  //   padding-top: -10em !important;
  //   overflow: auto !important;
}
